<template>
  <div>
   <div>
     <p  class="category" style="margin-bottom: 29px">留言管理</p>
   </div>
  <el-table
      id="table"
      :data="tableData"
      style="width: 100%">
    <el-table-column
        label="ID"
        width="80">
      <template slot-scope="scope">
        <span >{{ scope.row.id }}</span>
      </template>
    </el-table-column>
    <el-table-column
        label="姓名"
        width="120"
        >
      <template slot-scope="scope">
        <span v-html="scope.row.name"></span>
      </template>
    </el-table-column>
    <el-table-column
        label="电话"
        width="180">
      <template slot-scope="scope">
        <span style="">{{ scope.row.phone }}</span>
      </template>
    </el-table-column>
    <el-table-column
        label="地址"
        width="180">
      <template slot-scope="scope">
        <span style="">{{ scope.row.company }}</span>
      </template>
    </el-table-column>
    <el-table-column
        label="信息">
      <template slot-scope="scope">
        <span >{{ scope.row.message }}</span>
      </template>
    </el-table-column>
    <el-table-column
        label="提交时间"
        width="180">
      <template slot-scope="scope">
        <span >{{ scope.row.create_time }}</span>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="180">
      <template slot-scope="scope">
<!--        <el-button size="mini" @click="openCate(scope.row.id)">编辑</el-button>-->
        <el-button
            size="mini"
            type="danger"
            @click="deleteCate(scope.$index,scope.row)">删除</el-button>
      </template>
    </el-table-column>

  </el-table>
    <el-pagination
        layout="prev, pager, next"
        :page-size=10
        @current-change="getCate"
        :page-count="total"
    >
    </el-pagination>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "MessageView",
  data() {
    return {
      tableData: [],
      id: '',
      total:100
    }
  },
  components:{
  },
  created() {
    this.getCate();
  },
  methods: {
    openCate(id){
      this.id = id
      this.dialogVisible =true
    },

    getCate(page = 1){
      axios.get('/api/Message/index.html?page='+page).then((res)=>{
        if(res.data.code == 200){
          this.tableData = res.data.data;
          this.total = res.data.total
        }

      });
    },
    deleteCate(index,row){
      let tdata = this.tableData;
      this.$confirm("是否确认删除","提示",{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
      }).then(function (){
        axios.delete('/api/Message/deleteMessage.html',{data:{id:row.id}}).then((res)=>{
          if(res.data.code == 200){
            tdata.splice(index, 1);
          }
          alert(res.data.msg)
        });
      }).catch(function (){

      })

    }
  }
};
</script>