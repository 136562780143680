<template>
    <el-form ref="form" :model="form" label-width="100px">
      <input type="hidden" v-model="form.id" />
      <el-form-item label="下载标题">
        <el-input v-model="form.d_title"></el-input>
      </el-form-item>
      <el-form-item label="所属栏目">
        <el-select v-model="form.cate_id"  placeholder="请选择">
          <el-option
              v-for="item in cateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品附件">
        <el-upload
            class="upload-demo"
            action="/api/Upload/fileupload.html"
            :on-preview="handlePreview2"
            :on-remove="handleRemove2"
            :on-success="handleAvatarSuccess2"
            :file-list="form.fileList"
            list-type="text"
            :limit=1
            name="image[]"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            文件不超过50Mb
          </div>
        </el-upload>
        <el-input v-model="form.d_file" type="hidden"></el-input>
      </el-form-item>
      <el-form-item label="文章链接">
        <el-input v-model="form.d_url"></el-input>
      </el-form-item>
      <el-form-item label="发布时间">
        <el-date-picker
            value-format="yyyy:MM:dd HH:mm:ss"
            v-model="form.release_time"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number v-model="form.top" :min="0" :max="100" label=""></el-input-number>
      </el-form-item>
      <el-form-item label="状态">
        <el-radio-group v-model="form.d_status">
          <el-radio-button label="0">未审核</el-radio-button>
          <el-radio-button label="1">正常</el-radio-button>
          <el-radio-button label="2">推荐</el-radio-button>
          <el-radio-button label="3">暂停</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="handleClose">重置</el-button>
      </el-form-item>
    </el-form>
</template>

<script>
import axios from "axios";
export default {
  name: "getDownloadView",
  data(){
    return{
      form: {
        id: "",
        cate_id: "",
        d_title: "",
        top: 0,
        release_time: '',
        update_time: '',
        d_url: "",
        d_file: "",
        fileList:[],
        d_status: 0
      },
      cateList: [],
    }
  },
  // created() {
  //   this.getCategory();
  // },
  mounted() {
    let geturl = window.location.href;
    let geturlinfo = geturl.split('?')[1];
    let getqys = new URLSearchParams('?'+geturlinfo);
    let id = getqys.get('id');
    console.log(id);
    axios.get('/api/Category/easyList.html').then((res)=>{
      this.cateList = res.data;
    });
    if(id > 0){
      axios.get('/api/Download/getDownload.html?id='+id).then((res)=>{
        if(res.data.code == 200){
          this.form = res.data.data;
        }
      });
    }

  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods:{
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    resetData(){
      this.form = this.$options.data().form;
    },
    handleClose() {
      this.resetData()
    },
    handleAvatarSuccess(res) {
      let array = Object.values(res);
      this.form.c_img = array[0]['file_url'];
    },
    handleChange(value) {
      console.log(value);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleAvatarSuccess2(res) {
      let array = Object.values(res);
      // let img = this.form.d_file;
      // var imglist = [];
      // if(img) imglist = img.split(',');
      // imglist.push(array[0]['file_url']);
      // this.form.d_file = imglist.join(',');
      this.form.d_file = array[0]['file_url'];
    },
    handleRemove2(file, fileList) {
      this.form.d_file = '';
      // let img = this.form.d_file;
      // var imglist = img.split(',');
      // var url = this.expurl(file.url);
      // for(let i = 0; i < imglist.length; i++){
      //   if(imglist[i] == url){
      //     imglist.splice(i,1);
      //   }
      // }
      // this.form.d_file = imglist.join(',');
      console.log(fileList);
    },
    handlePreview2(file) {
      console.log(file);
    },
    onSubmit() {
      axios({
        method:'post',
        url:'/api/Download/setDownload.html',
        data:this.form
      }).then((res)=>{
        if(res.data.code != 200) {
          alert(res.data.msg);
        }
        else{
          alert(res.data.msg);
          // this.$props.id = res.data.id;
          this.form.id = res.data.id;
          // this.$router.go(0);
        }
      })
    },
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>

</style>