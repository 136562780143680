import { render, staticRenderFns } from "./getDownloadView.vue?vue&type=template&id=572eda0a&scoped=true&"
import script from "./getDownloadView.vue?vue&type=script&lang=js&"
export * from "./getDownloadView.vue?vue&type=script&lang=js&"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "572eda0a",
  null
  
)

export default component.exports